import React, { Component } from 'react';
import intl from 'react-intl-universal';
import './App.scss';
import { loadReCaptcha } from 'react-recaptcha-google';
import { Balloons } from './pages/default/Balloons';
import Footer from './pages/default/Footer';
import RouterExternal from './pages/default/RouterExternal';
import RouterMain from './pages/default/RouterMain';
import { DocusignProvider } from './components/DocusignContext';
import { oLocalStorage } from './config/EncodedLocalStorage';
import { ThemeProvider, ToastContainer } from "@stardust-ds/react";

const oLocales = {
  en: require('./locales/en.json'),
  'pt-br': require('./locales/pt-br.json')
};

class App extends Component {
  state = { initDone: false, drawerOpen: true, anchorEl: null };

  componentDidMount() {
    this.loadLocales();
    loadReCaptcha();
    
    // Carregar o script do UserGuiding e identificar o usuário após o login
    this.loadUserGuidingScript();

  }

  static getLanguage() {
    const cLanguage = oLocalStorage.get('cLanguage');

    if (cLanguage) return cLanguage;

    switch (intl.determineLocale()) {
      case 'en_US':
      case 'en-US':
      case 'us':
        return 'en';
      case 'pt-BR':
      case 'pt_BR':
      case 'pt':
      default:
        return 'pt-br';
    }
  }

  loadLocales() {
    intl
      .init({
        currentLocale: App.getLanguage(),
        locales: oLocales
      })
      .then(() => {
        this.setState({ initDone: true });
      });
  }

  loadUserGuidingScript() {
    const bIsLoggedIn = localStorage.getItem('cStorageToken');
    //add qrcode url to localstorage
    const pathArray = window.location.pathname.split('/');

    if (pathArray.includes('dashboard') && pathArray.includes('boards') && !bIsLoggedIn || bIsLoggedIn === 'undefined') {

      const dashboardIndex = pathArray.indexOf('dashboard');
      const boardsIndex = pathArray.indexOf('boards');

      // Check if 'boards' comes after 'dashboard' and if there are at least two elements after 'boards'
      if (boardsIndex > dashboardIndex && pathArray.length > boardsIndex + 2) {
        const param1 = +pathArray[boardsIndex + 1];
        const param2 = +pathArray[boardsIndex + 2];

        // Check if the two parameters after 'boards' are numbers
        if (!isNaN(param1) && !isNaN(param2) && !localStorage.getItem("qrCodeUrl")) {
          const qrCodeUrl = window.location.pathname.substring(window.location.pathname.indexOf('/') + 1);
          localStorage.setItem("qrCodeUrl", encodeURIComponent(qrCodeUrl));

        }
      }
      return
    }
    if (!bIsLoggedIn || bIsLoggedIn === 'undefined') return;

    // Verifica se o script já foi carregado para evitar duplicação
    if (!document.getElementById('userGuidingScript')) {
      (function (window, document, elScriptTagName, elUserGuidingGlobal, elUserGuidingLayer, elUserGuidingScriptID) {
        window[elUserGuidingLayer] = window[elUserGuidingLayer] || [];
        var elFirstScriptTag = document.getElementsByTagName(elScriptTagName)[0];
        var elNewScriptTag = document.createElement(elScriptTagName);
        elNewScriptTag.async = true;
        elNewScriptTag.src = 'https://static.userguiding.com/media/user-guiding-' + elUserGuidingScriptID + '-embedded.js';
        elNewScriptTag.id = 'userGuidingScript';
        elFirstScriptTag.parentNode.insertBefore(elNewScriptTag, elFirstScriptTag);

        if (window[elUserGuidingGlobal]) return;
        var elUserGuiding = window[elUserGuidingGlobal] = { q: [] };
        elUserGuiding.c = function (methodName) {
          return function () {
            elUserGuiding.q.push([methodName, arguments]);
          };
        };
        var methods = ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist'];
        for (var i = 0; i < methods.length; i += 1) {
          elUserGuiding[methods[i]] = elUserGuiding.c(methods[i]);
        }
      })(window, document, 'script', 'userGuiding', 'userGuidingLayer', '895571212ID');
    }

    // Identificar o usuário quando o script estiver carregado
    const identifyUser = () => {
      if (window.userGuiding) {

        const user = {
          id: oLocalStorage.get('nUserId'),  
          email: oLocalStorage.get('cEmailUser'), 
          name: oLocalStorage.get('cNameUser'), 
          createdAt: oLocalStorage.get('dCreateUser'), 
          companyId: oLocalStorage.get('nCustId'),  
          companyName: oLocalStorage.get('cCompanyName'),
          companyCreatedAt: oLocalStorage.get('dCreateUser'),
        };

        window.userGuiding.identify(user.id, {
          email: user.email,
          name: user.name,
          created_at: user.createdAt,
          company: {
            id: user.companyId,
            name: user.companyName,
            created_at: user.companyCreatedAt,
          },
        });
      }
    };

    // Executa a identificação imediatamente se o script já estiver carregado
    if (window.userGuiding) {
      identifyUser();
    } else {
      // Aguarda o carregamento do script
      const script = document.getElementById('userGuidingScript');
      script.onload = identifyUser;
    }

  }

  render() {
    const { initDone } = this.state;
    const bIsLoggedIn = localStorage.getItem('cStorageToken');
    const cPathname = window.location.pathname;

    if (bIsLoggedIn && bIsLoggedIn !== 'undefined') {
      return (
        <div>
          <ThemeProvider>
            <ToastContainer />
            <DocusignProvider>
              <main className={cPathname !== '/' ? 'card' : ''} id="container">
                <div className="MessageHolder centered" />
                {initDone && <RouterMain />}
              </main>
              {cPathname !== '/quality-panel-consultant' && <Balloons />}
              <Footer />
            </DocusignProvider>
          </ThemeProvider>
        </div>
      );
    }
    return <RouterExternal />;
  }
}

export default App;
